import { faqItems } from '@/constants';
import { defaultStyles } from '@/utils/defaultStyles';
import { useState } from 'react';

function FaqElement(props: { title: string; desc: JSX.Element }) {
  const [isActive, setIsActive] = useState(false);

  return (
    <div
      onClick={() => setIsActive(!isActive)}
      className="flex flex-col gap-4 p-6 bg-transparent border-[3px] border-solid border-[#CBBA9D] cursor-pointer"
    >
      <div className="flex flex-row justify-between gap-8 items-center">
        <div className="text-[25px] font-bold text-[#CBBA9D] uppercase tracking-widest ">
          {props.title}
        </div>
        <svg
          width="19"
          height="11"
          viewBox="0 0 19 11"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={`transition-all duration-300 ${isActive ? 'rotate-180' : ''}`}
        >
          <path
            d="M1.91585 2.0129C2.14552 1.78249 2.51849 1.78188 2.7489 2.01155L8.79407 8.03701C9.18433 8.426 9.81571 8.42599 10.206 8.03701L16.2511 2.01155C16.4815 1.78188 16.8545 1.78249 17.0842 2.0129C17.3138 2.24332 17.3132 2.61629 17.0828 2.84596L10.206 9.7004C9.81571 10.0894 9.18433 10.0894 8.79407 9.7004L1.91721 2.84596C1.68679 2.61629 1.68618 2.24332 1.91585 2.0129Z"
            fill="#CBBA9D"
            stroke="#CBBA9D"
          />
        </svg>
      </div>
      {isActive && (
        <div className="text-[#CBBA9D] text-[16px] w-[70%]">{props.desc}</div>
      )}
    </div>
  );
}

export default function FaqSection() {
  return (
    <section
      className={`flex flex-col xl:flex-row justify-between xl:gap-4 py-24 ${defaultStyles.paddingBig}`}
      id="faq"
    >
      <div className="flex flex-col gap-4">
        <div className="uppercase tracking-widest  text-[16px] text-[#CBBA9D]">
          CZYNNOŚCI NOTARIALNE
        </div>
        <div className="text-black text-[35px] md:text-[45px] font-bold">
          Do zakresu czynności notarialnych dokonywanych w naszej Kancelarii
          należą:
        </div>
        <div className="bg-[#A5A5A5] h-[64px] w-[1px]" />
        <button className="bg-transparent text-[16px] uppercase tracking-widest  text-white border-solid border-white outline-none p-4 w-[240px] cursor-pointer">
          SPRAWDŹ WSZYSTKIE
        </button>
      </div>
      <div className="flex flex-col gap-4 xl:min-w-[60%]">
        {faqItems.map((item, index) => (
          <FaqElement key={index} {...item} />
        ))}
      </div>
    </section>
  );
}
