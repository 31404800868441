import { defaultStyles } from '@/utils/defaultStyles';
import dynamic from 'next/dynamic';

const Map = dynamic(() => import('@/components/Map'), {
  ssr: false,
});

const items = [
  <>
    Kancelaria znajduje się w budynku przy skrzyżowaniu ulic Kazimierza Witalisa
    Szarskiego oraz ulicy Mińskiej, a wejście do budynku znajduje się od strony
    ulicy Szarskiego (wejście przez furtkę, za bramą wjazdową).
    <br /> Kancelaria znajduje się na I piętrze.
  </>,
  <>
    W pobliżu budynku znajdują się przystanki komunikacji miejskiej, gdzie
    kursują autobusy linii: 122, 132, 152, 107, 119, 241, 319, 607 oraz 927.
  </>,
  <>
    800 metrów od Kancelarii, z przystanku Rogowska (P+R), kursują także linie
    tramwajowe: 13 i 23.
  </>,
  <>
    Połączenia mogą Państwo sprawdzić na stronie internetowej{' '}
    <a href="https://jakdojade.pl/wroclaw" target="_blank">
      jakdojade.pl/wroclaw
    </a>
  </>,
  <>Znajdujemy się blisko lotniska (6 km, 10 minut samochodem),</>,
  <>Znajdujemy się blisko autostrady A4 oraz obwodnicy Wrocławia (S8).</>,
  <>
    Bezpośrednio przed budynkiem Kancelarii znajdują się bezpłatne miejsca
    parkingowe. Zaparkować można również na dużym ogólnodostępnym parkingu przy
    skrzyżowaniu ulic Kazimierza Witalisa Szarskiego oraz ulicy Mińskiej (sklepy
    RTV EURO AGD, Komfort, Biedronka, Rossmann), z parkometru należy pobrać
    bilet, co umożliwia nieodpłatny postój przez 150 minut.
  </>,
];

export default function MapSection() {
  return (
    <section className={`flex flex-col justify-center items-center`} id="map">
      <div className="uppercase tracking-widest  text-[16px] text-[#CBBA9D]">
        Jak dojechać
      </div>
      <div className="text-black text-[35px] md:text-[45px] font-bold">
        Dojazd i Parking
      </div>
      <div className="bg-[#A5A5A5] h-[64px] w-[1px] mb-4" />
      <div
        className={`bg-[#CBBA9D] py-12 flex flex-col gap-4 ${defaultStyles.paddingBig}`}
      >
        {items.map((item, index) => (
          <div className="flex flex-row gap-2" key={index}>
            <svg
              width="22"
              height="22"
              viewBox="0 0 22 22"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="min-w-[22px] min-h-[22px] max-w-[22px] max-h-[22px]"
            >
              <g clipPath="url(#clip0_99_654)">
                <path
                  d="M21.957 11C21.957 13.1713 21.3125 15.2828 20.0933 17.1064C19.9276 17.3543 19.6554 17.4883 19.3781 17.4883C19.2139 17.4883 19.0479 17.4415 18.9012 17.3433C18.5066 17.0796 18.4007 16.5458 18.6644 16.1512C19.6941 14.6112 20.2383 12.83 20.2383 11C20.2383 10.2136 20.1399 9.43265 19.9459 8.67902C19.8276 8.21945 20.1043 7.75083 20.5639 7.63249C21.0238 7.51433 21.4921 7.79094 21.6104 8.25051C21.8404 9.14412 21.957 10.0691 21.957 11ZM15.7581 18.9447C14.3146 19.819 12.6545 20.2813 10.957 20.2813C5.83939 20.2813 1.67578 16.1177 1.67578 11C1.67578 5.88236 5.83939 1.71875 10.957 1.71875C12.8519 1.71875 14.6738 2.28574 16.2262 3.35845C16.6167 3.62818 17.1519 3.53032 17.4218 3.13991C17.6916 2.7495 17.5939 2.21423 17.2033 1.94434C15.3623 0.672394 13.2025 0 10.957 0C8.01888 0 5.25646 1.14421 3.17885 3.22182C1.10124 5.29943 -0.0429688 8.06185 -0.0429688 11C-0.0429688 13.9382 1.10124 16.7006 3.17885 18.7782C5.25646 20.8558 8.01888 22 10.957 22C12.9685 22 14.9367 21.4518 16.6487 20.4147C17.0547 20.1688 17.1845 19.6404 16.9386 19.2344C16.6925 18.8284 16.1641 18.6988 15.7581 18.9447ZM11.04 13.4919C10.9038 13.4972 10.7768 13.4489 10.6781 13.3549L6.17726 9.08739C5.83284 8.76076 5.28885 8.7752 4.96239 9.11962C4.63576 9.46404 4.65019 10.008 4.99462 10.3345L9.49459 14.6013C9.91018 14.9964 10.4478 15.2109 11.0175 15.2109C11.048 15.2109 11.0786 15.2103 11.1091 15.2091C11.7125 15.1848 12.2667 14.9236 12.6696 14.4736C12.6798 14.4623 12.6896 14.4508 12.6991 14.4388L21.7674 3.15955C22.0648 2.78961 22.0061 2.24864 21.6361 1.95139C21.2664 1.65396 20.7252 1.71271 20.4278 2.08264L11.3773 13.34C11.2884 13.4328 11.1692 13.4867 11.04 13.4919Z"
                  fill="white"
                />
              </g>
              <defs>
                <clipPath id="clip0_99_654">
                  <rect width="22" height="22" fill="white" />
                </clipPath>
              </defs>
            </svg>

            <div className="text-white text-[16px]">{item}</div>
          </div>
        ))}
      </div>
      <Map />
    </section>
  );
}
