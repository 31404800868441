import AboutUsSection from '@/components/AboutUsSection';
import AmenitiesSection from '@/components/AmenitiesSection';
import ContactFormSection from '@/components/ContactFormSection';
import ContactSection from '@/components/ContactSection';
import FaqSection from '@/components/FaqSection';
import FullWidthGallery from '@/components/FullWidthGallery';
import LinksSection from '@/components/LinksSection';
import MapSection from '@/components/MapSection';
import Navbar from '@/components/Navbar';
import Head from 'next/head';

export default function Home() {
  return (
    <>
      <Head>
        <title>Kancelaria Notarialna</title>
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{
            __html: JSON.stringify({
              '@context': 'https://schema.org',
              '@type': 'LegalService',
              name: 'Kancelaria Notarialna Maria Czarniak Patrycja Dorczyńska Sara Podkówka spółka cywilna',
              url: 'https://notariuszwroclawminska.pl',
              logo: 'https://notariuszwroclawminska.pl/logo.png',
              image: 'https://notariuszwroclawminska.pl/about.png',
              address: {
                '@type': 'PostalAddress',
                streetAddress: 'ul. Mińska 54-56 lok. 1A',
                addressLocality: 'Wrocław',
                postalCode: '54-610',
                addressCountry: 'PL',
              },
              contactPoint: {
                '@type': 'ContactPoint',
                telephone: '+48 799-088-796',
                contactType: 'Customer Service',
                availableLanguage: 'Polish',
              },
              openingHours:
                'Mo 09:00-16:00, Tu 09:00-18:00, We 09:00-16:00, Th 09:00-18:00, Fr 08:00-14:00, Sa 10:00-13:00',
              priceRange: '$$$',
            }),
          }}
        />
        <meta
          name="description"
          content="Kancelaria notarialna we Wrocławiu - kompleksowe usługi notarialne. Skontaktuj się z nami, aby uzyskać pomoc prawną na najwyższym poziomie."
        />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link rel="icon" href="/favicon.ico" />
      </Head>
      <Navbar withoutHero />
      <div className="mt-[92px]" />
      <FullWidthGallery />
      <LinksSection />
      <AboutUsSection />
      <FaqSection />
      <MapSection />
      <AmenitiesSection />
      <ContactSection />
      <ContactFormSection />
    </>
  );
}
