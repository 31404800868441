import createStyleForBackgroundImage from '@/utils/createStyleForBackgroundImage';
import { defaultStyles } from '@/utils/defaultStyles';

const timeItems = [
  {
    day: 'Poniedziałek',
    hours: '9:00-16:00',
  },
  {
    day: 'Wtorek',
    hours: '9:00-18:00',
  },
  {
    day: 'Środa',
    hours: '9:00-16:00',
  },
  {
    day: 'Czwartek',
    hours: '9:00-18:00',
  },
  {
    day: 'Piątek',
    hours: '8:00-14:00',
  },
  {
    day: 'Sobota',
    hours: '10:00-13:00',
  },
];

const contactItems = [
  {
    name: 'Notariusz Maria Czarniak',
    phone: '799 088 567',
  },
  {
    name: 'Notariusz Patrycja Dorczyńska',
    phone: '799 088 503',
  },
  {
    name: 'Notariusz Sara Podkówka',
    phone: '799 088 796',
  },
];

export default function ContactSection() {
  return (
    <section
      className={`flex flex-col justify-center items-center gap-4 py-12 ${defaultStyles.paddingBig}`}
      id="contact"
      style={createStyleForBackgroundImage('/contact-bg.png')}
    >
      <div className="uppercase tracking-widest text-[16px] text-[#CBBA9D]">
        Kontakt
      </div>
      <div className="text-white text-[35px] md:text-[45px] font-bold">
        Skontaktuj się z nami
      </div>
      <div className="bg-white h-[64px] w-[1px] mb-4" />
      <div className="flex flex-col xl:flex-row justify-between w-full gap-4">
        <div className="text-[16px] text-white">
          Kancelaria Notarialna Maria Czarniak Patrycja Dorczyńska Sara Podkówka
          spółka cywilna
          <br />
          Adres: ul. Mińska 54-56 lok. 1A (I piętro), 54-610 Wrocław <br />
          e-mail: kontakt@notariuszwroclawminska.pl
          <br />
          NIP: 8943240555
          <br />
          REGON: 529209934
          <br />
          <br />
          Numer rachunku podstawowego:
          <br />
          46 1140 2004 0000 3502 8505 9495 (mBank S.A.)
          <br />
          Numer rachunku depozytowego:
          <br />
          73 1140 2004 0000 3602 8508 4471 (mBank S.A.)
        </div>
        <div className="flex flex-col gap-2">
          <div className="text-[25px] text-white mb-2">Godziny otwarcia:</div>
          {timeItems.map((item, index) => (
            <div
              key={index}
              className="text-white text-[16px] border border-solid border-white border-l-0 border-r-0 border-t-0 xl:w-[700px] pb-2"
            >
              {item.day} {item.hours}
            </div>
          ))}
        </div>
      </div>
      <div className="flex flex-row gap-8 md:gap-4 justify-between w-full flex-wrap mt-12">
        {contactItems.map((item, index) => (
          <div key={index} className="flex flex-row items-center gap-4">
            <div className="flex flex-col justify-center items-center rounded-full bg-transparent border border-solid border-white min-h-[73px] max-h-[73px] min-w-[73px] max-w-[73px]">
              <svg
                width="27"
                height="27"
                viewBox="0 0 27 27"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M23.2495 21.8707C23.2495 22.2709 23.1606 22.6822 22.9716 23.0824C22.7826 23.4826 22.538 23.8606 22.2156 24.2164C21.6709 24.8167 21.0706 25.2502 20.3924 25.5282C19.7254 25.8061 19.0028 25.9506 18.2247 25.9506C17.0907 25.9506 15.879 25.6838 14.6005 25.1391C13.3221 24.5943 12.0437 23.8606 10.7763 22.9379C9.49788 22.0041 8.28614 20.9702 7.12998 19.8252C5.98494 18.669 4.95107 17.4573 4.02837 16.19C3.11678 14.9226 2.38307 13.6553 1.84946 12.3991C1.31585 11.1318 1.04904 9.92003 1.04904 8.76387C1.04904 8.00792 1.18244 7.28532 1.44925 6.61831C1.71606 5.94018 2.1385 5.31763 2.72769 4.76179C3.43917 4.06142 4.21736 3.7168 5.04001 3.7168C5.35128 3.7168 5.66255 3.7835 5.94048 3.9169C6.22952 4.0503 6.48521 4.25041 6.68531 4.53945L9.26443 8.17467C9.46453 8.4526 9.60905 8.70828 9.70911 8.95286C9.80916 9.18631 9.86474 9.41977 9.86474 9.63099C9.86474 9.89779 9.78692 10.1646 9.63129 10.4203C9.48677 10.676 9.27555 10.9428 9.00874 11.2096L8.16386 12.0878C8.04157 12.2101 7.98599 12.3546 7.98599 12.5325C7.98599 12.6214 7.9971 12.6993 8.01934 12.7882C8.05269 12.8771 8.08604 12.9438 8.10827 13.0105C8.30838 13.3774 8.653 13.8554 9.14214 14.4335C9.6424 15.0116 10.176 15.6008 10.7541 16.19C11.3544 16.7792 11.9325 17.3239 12.5217 17.8241C13.0998 18.3133 13.5778 18.6468 13.9558 18.8469C14.0113 18.8691 14.078 18.9025 14.1559 18.9358C14.2448 18.9692 14.3337 18.9803 14.4338 18.9803C14.6228 18.9803 14.7673 18.9136 14.8896 18.7913L15.7345 17.9575C16.0124 17.6796 16.2792 17.4684 16.5349 17.335C16.7906 17.1794 17.0463 17.1015 17.3242 17.1015C17.5354 17.1015 17.7577 17.146 18.0023 17.2461C18.2469 17.3461 18.5026 17.4906 18.7805 17.6796L22.4602 20.2921C22.7492 20.4922 22.9493 20.7257 23.0716 21.0036C23.1828 21.2815 23.2495 21.5594 23.2495 21.8707Z"
                  stroke="white"
                  strokeWidth="1.60083"
                  strokeMiterlimit="10"
                />
                <path
                  d="M19.392 11.4993C19.392 10.8323 18.8695 9.80954 18.0913 8.97578C17.3798 8.20871 16.4349 7.6084 15.5011 7.6084"
                  stroke="white"
                  strokeWidth="1.60083"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M23.2829 11.4986C23.2829 7.19639 19.8033 3.7168 15.5011 3.7168"
                  stroke="white"
                  strokeWidth="1.60083"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>

            <div className="flex flex-col">
              <div className="text-[25px] text-white">{item.name}</div>

              <div className="font-bold text-[35px] md:text-[50px] text-white">
                {item.phone}
              </div>
            </div>
          </div>
        ))}
      </div>
      <div className="bg-white h-[64px] w-[1px]" />
      <div className="text-white text-[25px] xl:w-[60%] text-center">
        <span className="font-bold">
          W celu umówienia czynności notarialnych w innych godzinach i dniach
        </span>{' '}
        prosimy o kontakt telefoniczny z notariuszem
      </div>
    </section>
  );
}
