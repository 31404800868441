import createStyleForBackgroundImage from '@/utils/createStyleForBackgroundImage';

export default function FullWidthGallery() {
  return (
    <section
      className="flex px-4 flex-col gap-4 justify-center items-center py-44 xl:py-64"
      style={createStyleForBackgroundImage('/hero-bg.png', {
        withDarkOverlay: true,
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
      })}
    >
      <div className="text-[16px] text-white uppercase tracking-widest  text-center">
        Kancelaria Notarialna
      </div>
      <div className="text-[35px] md:text-[45px] font-bold text-white text-center xl:w-[50%]">
        Witamy Państwa na stronie internetowej naszej Kancelarii.
      </div>
      <div className="h-[64px] w-[1px] bg-[#A5A5A5]" />
      <div>
        <div className="flex flex-col gap-4">
          <div className="flex flex-col lg:flex-row gap-4 justify-center items-center">
            <button
              onClick={() => window.location.assign('/kontakt')}
              className="bg-transparent text-[16px] uppercase tracking-widest  text-white border-solid border-white outline-none p-4 w-[184px] cursor-pointer"
            >
              Kontakt
            </button>
            <button
              onClick={() => {
                const element = document.getElementById('contactForm');
                if (element) {
                  window.scrollTo({
                    top: element.offsetTop - 100,
                    behavior: 'smooth',
                  });
                }
              }}
              className="bg-transparent text-[16px] uppercase tracking-widest  text-white border-solid border-white outline-none p-4 w-[184px] cursor-pointer"
            >
              Napisz do nas
            </button>
          </div>
          <div className="flex flex-col gap-1 self-center">
            <div className="text-white text-[10px]">Lub zadzwoń</div>
            <div className="text-white text-[16px]">799-088-796</div>
            <div className="text-white text-[16px]">799-088-503</div>
            <div className="text-white text-[16px]">799-088-567</div>
          </div>
        </div>
      </div>
    </section>
  );
}
