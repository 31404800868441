import createStyleForBackgroundImage from '@/utils/createStyleForBackgroundImage';
import { defaultStyles } from '@/utils/defaultStyles';

export default function AboutUsSection() {
  return (
    <section
      id="about"
      className={`flex flex-col xl:flex-row justify-between bg-[#A4AA7D]`}
    >
      <div className={`flex flex-col gap-4 py-24 ${defaultStyles.paddingBig}`}>
        <div className="text-[16px] uppercase tracking-widest  text-white">
          o nas
        </div>
        <div className="text-[35px] md:text-[45px] text-white font-bold">
          Kancelaria Notarialna
        </div>
        <div className="h-[64px] w-[1px] bg-white" />
        <div className="text-white text-[16px]">
          Kancelaria notarialna zlokalizowana jest we Wrocławiu przy{' '}
          <span className="font-bold">ulicy Mińskiej 54-56</span> (Muchobór
          Wielki, Wrocław Fabryczna),{' '}
          <span className="font-bold">na I piętrze</span>.<br />
          <br />
          Oferujemy Państwu profesjonalną obsługę w zakresie dokonywania
          wszystkich czynności notarialnych, określonych w ustawie z 14 lutego
          1991 roku Prawo o notariacie.
          <br />
          <span className="font-bold">
            Jesteśmy dla Państwa otwarci także w soboty.
          </span>
          <br />
          <br />W celu umówienia czynności notarialnych{' '}
          <span className="font-bold">w innych godzinach i dniach</span> prosimy
          o kontakt telefoniczny z notariuszem, pozostajemy do Państwa
          dyspozycji.
          <br />
          <br />W uzasadnionych przypadkach czynność notarialna może być
          dokonana także{' '}
          <span className="font-bold">
            poza siedzibą Kancelarii Notarialnej
          </span>
          .<br />
          <br />W ramach działalności udzielamy Klientom Kancelarii{' '}
          <span className="font-bold">nieodpłatnych porad prawnych</span>.
          <br />
          Oferujemy Państwu także pod oknami kancelarii{' '}
          <span className="font-bold">bezpłatne miejsca parkingowe</span>.
        </div>
      </div>
      <div
        className="w-full h-[400px] xl:h-auto"
        style={createStyleForBackgroundImage('/about.png', {
          withDarkOverlay: false,
          backgroundPosition: 'center',
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
        })}
      />
    </section>
  );
}
