const items = [
  {
    svg: (
      <svg
        width="60"
        height="59"
        viewBox="0 0 60 59"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_17_186)">
          <path
            d="M35.9119 45.1905L43.4473 34.9124C46.8169 30.3187 49.8409 25.7031 49.8409 19.7647C49.8409 8.96058 41.0719 0.165268 30.278 0.121948H30.1954C19.3663 0.121948 10.5549 8.93334 10.5549 19.7647C10.5549 25.7018 13.5788 30.3187 16.9484 34.9124L24.4834 45.1905H15.7216L10.6429 58.6585H49.7529L44.6741 45.1905H35.9119ZM19.7137 32.884C16.58 28.6096 13.9848 24.6719 13.9848 19.7647C13.9848 10.8492 21.2161 3.59291 30.1199 3.55183H30.1977C39.1372 3.55183 46.411 10.8256 46.411 19.7647C46.411 24.6719 43.8158 28.6105 40.682 32.884C40.682 32.884 30.621 46.6415 30.2115 47.2015L19.7137 32.884ZM15.6024 55.2287L18.0939 48.6203H26.9978L30.1659 52.9407C30.1659 52.9407 32.8138 49.4564 33.4221 48.6203H42.3018L44.7929 55.2287H15.6024Z"
            fill="#232323"
          />
          <path
            d="M42.2951 19.7646C42.2951 13.0938 36.8676 7.6676 30.1976 7.6676C23.5277 7.6676 18.1006 13.0938 18.1006 19.7646C18.1006 26.4346 23.5277 31.8607 30.1976 31.8607C36.8676 31.8607 42.2951 26.4346 42.2951 19.7646ZM30.1976 28.4309C25.419 28.4309 21.5305 24.5437 21.5305 19.7646C21.5305 14.986 25.419 11.0975 30.1976 11.0975C34.9767 11.0975 38.8652 14.986 38.8652 19.7646C38.8652 24.5437 34.9767 28.4309 30.1976 28.4309Z"
            fill="#232323"
          />
        </g>
        <defs>
          <clipPath id="clip0_17_186">
            <rect
              width="58.5366"
              height="58.5366"
              fill="white"
              transform="translate(0.951263 0.121948)"
            />
          </clipPath>
        </defs>
      </svg>
    ),
    title: 'Dojazd & Parking',
    desc: 'consectetur adipiscing elit. Suspendisse',
    route: '/parking',
  },
  {
    svg: (
      <svg
        width="59"
        height="59"
        viewBox="0 0 59 59"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_17_125)">
          <path
            d="M56.1504 21.9589L49.2358 7.08382L33.8947 10.2739C33.2822 9.31781 32.3628 8.57684 31.2759 8.19133V1.26526H27.846V8.19133C26.0959 8.81202 24.7785 10.354 24.4804 12.2317L9.67592 15.3104L2.97153 29.7332H0.292685V32.1913C0.292685 38.0227 5.03678 42.7668 10.8681 42.7668C16.6995 42.7668 21.4436 38.0227 21.4436 32.1913V29.7332H18.7647L13.3351 18.0527L25.1036 15.6055C25.7124 16.6596 26.6834 17.4786 27.846 17.891V47.1113H23.8004C21.0509 47.1113 18.814 49.3482 18.814 52.0977V54.0854H15.0412V57.5153H44.1951V54.0854H40.4223V52.0977C40.4223 49.3482 38.1854 47.1113 35.4359 47.1113H31.2759V17.891C33.0986 17.2446 34.4515 15.5988 34.673 13.6154L45.259 11.4142L40.3572 21.9589H37.6784V24.4169C37.6784 30.2483 42.4224 34.9924 48.2538 34.9924C54.0852 34.9924 58.8293 30.2483 58.8293 24.4169V21.9589H56.1504ZM36.9924 52.0977V54.0854H22.2439V52.0977C22.2439 51.2394 22.9421 50.5412 23.8004 50.5412H35.4358C36.2942 50.5412 36.9924 51.2394 36.9924 52.0977ZM10.8681 39.3369C7.25751 39.3369 4.2638 36.6454 3.78842 33.1631H17.9479C17.4725 36.6454 14.4788 39.3369 10.8681 39.3369ZM6.75389 29.7332L10.8681 20.8824L14.9824 29.7332H6.75389ZM29.561 14.7561C28.6154 14.7561 27.846 13.9868 27.846 13.0412C27.846 12.0956 28.6154 11.3262 29.561 11.3262C30.5066 11.3262 31.2759 12.0956 31.2759 13.0412C31.2759 13.9868 30.5066 14.7561 29.561 14.7561ZM48.2538 13.1081L52.3681 21.9589H44.1396L48.2538 13.1081ZM48.2538 31.5625C44.6432 31.5625 41.6495 28.871 41.1741 25.3887H55.3335C54.8582 28.871 51.8644 31.5625 48.2538 31.5625Z"
            fill="#232323"
          />
        </g>
        <defs>
          <clipPath id="clip0_17_125">
            <rect
              width="58.5366"
              height="58.5366"
              fill="white"
              transform="translate(0.292681 0.121948)"
            />
          </clipPath>
        </defs>
      </svg>
    ),
    title: 'Czynności notarialne',
    desc: 'consectetur adipiscing elit. Suspendisse',
    route: '/czynnosci-notarialne',
  },
  {
    svg: (
      <svg
        width="59"
        height="59"
        viewBox="0 0 59 59"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_17_129)">
          <path
            d="M20.1846 0.121948L7.60513 12.7013V58.6585H51.5167V0.121948H20.1846ZM19.1772 5.9899V11.6942H13.4729L19.1772 5.9899ZM11.0421 55.2215V15.1312H22.6142V3.55903H48.0794V55.2215H11.0421Z"
            fill="#232323"
          />
          <path
            d="M41.3482 19.5911H18.1279V23.0278H41.3482V19.5911Z"
            fill="#232323"
          />
          <path
            d="M41.3482 27.8777H18.1279V31.3144H41.3482V27.8777Z"
            fill="#232323"
          />
          <path
            d="M41.3482 36.1654H18.1279V39.6021H41.3482V36.1654Z"
            fill="#232323"
          />
          <path
            d="M36.3074 44.4519H18.1279V47.8886H36.3074V44.4519Z"
            fill="#232323"
          />
          <path
            d="M41.3493 11.3033H28.1534V14.7401H41.3493V11.3033Z"
            fill="#232323"
          />
        </g>
        <defs>
          <clipPath id="clip0_17_129">
            <rect
              width="58.5366"
              height="58.5366"
              fill="white"
              transform="translate(0.292664 0.121948)"
            />
          </clipPath>
        </defs>
      </svg>
    ),
    title: 'Dokumenty & opłaty',
    desc: 'consectetur adipiscing elit. Suspendisse',
    route: '/dokumenty',
  },
  {
    svg: (
      <svg
        width="59"
        height="59"
        viewBox="0 0 59 59"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_17_173)">
          <path
            d="M53.5137 12.2408H25.9604C23.1235 12.2408 20.8155 14.5488 20.8155 17.3857V19.1006H5.26677C2.42991 19.1006 0.121948 21.4086 0.121948 24.2454V41.3948C0.121948 44.2317 2.42991 46.5396 5.26677 46.5396H32.8201C35.657 46.5396 37.9649 44.2317 37.9649 41.3948V39.6799H53.5137C56.3506 39.6799 58.6585 37.3719 58.6585 34.5351V17.3857C58.6585 14.5488 56.3506 12.2408 53.5137 12.2408ZM34.5351 41.3948C34.5351 42.3404 33.7657 43.1097 32.8201 43.1097H5.26677C4.32115 43.1097 3.55183 42.3404 3.55183 41.3948V24.2454C3.55183 23.2998 4.32115 22.5305 5.26677 22.5305H32.8201C33.7657 22.5305 34.5351 23.2998 34.5351 24.2454V29.3902H31.1052V25.9604H6.9817V39.6799H25.9604H34.5351V41.3948ZM37.67 22.5305H48.3689V29.3902H37.9649V24.2454C37.9649 23.6442 37.8603 23.0673 37.67 22.5305ZM20.8155 29.3902V34.5351C20.8155 35.1363 20.9202 35.7132 21.1105 36.25H10.4116V29.3902H20.8155ZM55.2287 34.5351C55.2287 35.4807 54.4593 36.25 53.5137 36.25H25.9604C25.0147 36.25 24.2454 35.4807 24.2454 34.5351V29.3902H27.6753V32.8201H51.7988V19.1006H32.8201H24.2454V17.3857C24.2454 16.44 25.0147 15.6707 25.9604 15.6707H53.5137C54.4593 15.6707 55.2287 16.44 55.2287 17.3857V34.5351Z"
            fill="#232323"
          />
        </g>
        <defs>
          <clipPath id="clip0_17_173">
            <rect
              width="58.5366"
              height="58.5366"
              fill="white"
              transform="translate(0.121948 0.121948)"
            />
          </clipPath>
        </defs>
      </svg>
    ),
    title: 'Przydatne linki',
    desc: 'consectetur adipiscing elit. Suspendisse',
    route: '/przydatne-linki',
  },
];

function ItemCard(props: {
  svg: JSX.Element;
  title: string;
  desc: string;
  route: string;
}) {
  return (
    <div
      className="flex flex-col gap-4 px-4 py-12 border border-solid lg:border-b-0 lg:border-t-0 border-[#D4D4D4] hover:bg-[#efe5e0] transition-all duration-300 cursor-pointer"
      onClick={() => window.location.assign(props.route)}
    >
      <div className="flex flex-col justify-center items-center w-[120px] h-[120px] rounded-full bg-[#efe5e0]">
        {props.svg}
      </div>
      <div className="flex flex-row gap-4 justify-between items-center">
        <div className="text-[25px] font-bold text-black">{props.title}</div>
        <svg
          className="cursor-pointer"
          width="56"
          height="56"
          viewBox="0 0 56 56"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M43.8537 47.7072L11.7561 15.6097"
            stroke="black"
            strokeWidth="1.7561"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M43.8537 24.1614V47.7072H20.3078"
            stroke="black"
            strokeWidth="1.7561"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </div>
    </div>
  );
}

export default function LinksSection() {
  return (
    <section className="grid grid-cols-1 lg:grid-cols-2 2xl:grid-cols-4">
      {items.map((item, index) => (
        <ItemCard key={index} {...item} />
      ))}
    </section>
  );
}
